<div>
  <button
    [class]="buttonClass"
    [ngClass]="{ 'button--disabled': !_enabled, loading: loading }"
    (click)="onClickButton($event)"
  >
    <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
    <ng-content></ng-content>
  </button>
</div>
