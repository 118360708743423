import { NgClass } from "@angular/common";
import { Component, Input, OnChanges, output, SimpleChange, SimpleChanges } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";

export type SafeButtonStyle = "primary" | "secondary" | "red";

@Component({
  selector: "app-safe-button",
  templateUrl: "./safe-button.component.html",
  styleUrls: ["./safe-button.component.scss"],
  imports: [AngularSvgIconModule, NgClass],
})
export class SafeButtonComponent implements OnChanges {
  @Input() disabled = false;
  @Input() loading = false;
  @Input() small = false;
  @Input() btnStyle: SafeButtonStyle = "primary";
  @Input() loaderAfterClick = false;

  onClick = output<MouseEvent>();

  // handle disabled state without changing the input value
  public _enabled = false;

  constructor() {
    this._enabled = !this.disabled;
  }

  get buttonClass(): string {
    const cls = ["button"];
    switch (this.btnStyle) {
      case "primary":
        cls.push("button--primary");
        break;
      case "secondary":
        cls.push("button--secondary");
        break;
      case "red":
        cls.push("button--red");
        break;
    }
    if (this.small) {
      cls.push("button--small");
    }
    return cls.join(" ");
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disabledChange: SimpleChange = changes.disabled;
    if (disabledChange !== undefined) {
      this._enabled = !disabledChange.currentValue;
    }
  }

  onClickButton(event: MouseEvent) {
    event.stopPropagation();
    if (this._enabled) {
      this._enabled = false;
      this.onClick.emit(event);
      if (this.loaderAfterClick) {
        this.loading = true;
      }
    }
  }
}
